import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getAllQuestions, getAllServices, getGeneralInformationsFromWordpress } from "../../api/ListOfServicesPerCategory";
import { getAllRepresentativeAndApplicant } from "../../api/myCompany";
import CenterLoading from "../../components/CenterLoading/CenterLoading";
import LoginOrRegisterModal from "../../components/LoginOrRegisterModal/LoginOrRegisterModal";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import ServiceDirectoryMenu from "../../components/ServiceDirectoryMenu/ServiceDirectoryMenu";
import TextInformation from "../../components/TextInformation/TextInformation";
import { ROLE_TYPE } from "../../constants/constants";
import { UpdateAppSubHeaderTitle } from "../../redux/actions/UiActions";
import { MediumHeightDivider, Row, RowBodyDivider, SmallHeightDivider } from "../../theme/Styles";
import { localDirections } from "./AllServicesConstants";
import DialogSelect from "./components/DialogSelect";
import { Container } from "./styles/AllServicesStyles";

function AllServices() {
    const matchesWidth = useMediaQuery("(min-width:768px)");
    const history = useHistory();
    const { categoryID } = useParams();
    const dispatch = useDispatch();
    const { authenticated, rolBase } = useSelector((state) => state.authReducer);
    const [open, setOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [service, setService] = useState("");

    const { data: generalInformationData, isLoading: generalInformationDataLoading } = useQuery(["generalInformationData"], () =>
        getGeneralInformationsFromWordpress()
    );
    const { data: allQuestionsData, isLoading: allQuestionsDataLoading } = useQuery(["allQuestionsData"], () => getAllQuestions());
    const { data: listOfServices, isLoading: listOfServicesLoading } = useQuery(["listOfServices"], () => getAllServices());
    const { data: allCompanyImIn, isLoading: allCompanyImInLoading } = useQuery(["allRepresentativeApplicantData"], () =>
        getAllRepresentativeAndApplicant()
    );

    const [loginOrRegisterModalStatus, setLoginOrRegisterModalStatus] = useState(false);
    const [currentDirection, setCurrentDirection] = useState();

    const handleOpenModal = (service) => {
        if (rolBase == ROLE_TYPE.COMPANY) {
            Swal.fire({
                title: "Información",
                text: "No puede realizar una solicitud directamente, debe ser a través de un representante",
                icon: "warning",
            });
            return;
        }
        if (authenticated) {
            if (companies?.length == 0) {
                history.push(`/app/requestService/${service.id}`);
                return;
            }
            setSelectedCompany(null);
            setService(service);
            setOpen(!open);
        } else {
            setLoginOrRegisterModalStatus(!loginOrRegisterModalStatus);
        }
    };

    const handleServiceRequest = () => {
        if (!selectedCompany) {
            history.push(`/app/requestService/${service.id}`);
        } else {
            const [company_id, company_name] = selectedCompany?.split(",");
            history.push(`/app/requestService/${service.id}/${company_id}/${company_name}`);
        }

        setOpen(!open);
    };

    useLayoutEffect(() => {
        //find direction in local info
        let localCurrentDirection = localDirections.find((direction) => direction.id === 0);
        // TITLE OF SUBHEADER APP
        dispatch(UpdateAppSubHeaderTitle(localCurrentDirection.title));
        setCurrentDirection(localCurrentDirection);
    }, [dispatch]);

    useEffect(() => {
        if (allCompanyImIn?.payload) {
            const companies = allCompanyImIn.payload.map((x) => {
                return { company_name: x.company_name, id: x.id };
            });
            setCompanies(companies);
        }
    }, [allCompanyImIn]);

    if (generalInformationDataLoading || allQuestionsDataLoading || listOfServicesLoading || allCompanyImInLoading)
        return <CenterLoading />;

    return (
        <Container>
            <LoginOrRegisterModal
                open={loginOrRegisterModalStatus}
                onBackDropClick={() => setLoginOrRegisterModalStatus(false)}
                onCloseClick={() => setLoginOrRegisterModalStatus(false)}
            />
            <Row>
                {matchesWidth && (
                    <Fragment>
                        <ServiceDirectoryMenu />
                        <RowBodyDivider />
                    </Fragment>
                )}

                <Container style={{ width: "100%" }}>
                    <TextInformation
                        title="Información general"
                        content={generalInformationData?.find((item) => item.id === currentDirection?.wordpressID)?.descriptionGeneral}
                    />
                    <SmallHeightDivider />

                    {listOfServices?.map((item) => (
                        <Fragment key={item.id}>
                            <TextInformation title={item.name} />
                            <SmallHeightDivider />
                            <Grid
                                alignItems="center"
                                container
                                direction="row"
                                justifyContent={!matchesWidth ? "center" : "flex-start"}
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                {item.services?.map((service) => (
                                    <Grid key={service.id} item>
                                        <ServiceCard
                                            itemId={service.id}
                                            title={service.name}
                                            subTitle={service.description}
                                            relationTo={localDirections.find((direction) => direction.id === service.direction_id).title}
                                            onRequestPress={() => handleOpenModal(service)}
                                            disableRequestButton={service.type_id === 1 ? true : false}
                                            OnViewInformationPress={() => history.push(`/app/serviceDescription/${service.id}`)}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <MediumHeightDivider />
                        </Fragment>
                    ))}
                    <MediumHeightDivider />
                </Container>
            </Row>
            <DialogSelect
                open={open}
                setOpen={setOpen}
                title="¿Cómo desea continuar?"
                setValue={setSelectedCompany}
                value={selectedCompany}
                data={companies}
                onClick={handleServiceRequest}
            />
        </Container>
    );
}

export default AllServices;
